import { defineStore } from 'pinia';
interface State {
  player: null | any;
  isPlaying: boolean;
}

const state = (): State => ({
  player: null,
  isPlaying: false,
});

const useOpening = defineStore('play', {
  state,
  actions: {
    updatePlayer(player: any) {
      this.player = player;
    },
    updateIsPlaying(state: boolean) {
      this.isPlaying = state;
    },
  },
});

export default useOpening;
