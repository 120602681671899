import api from './api';
import { MediaType } from '@/types/enums';

if (process.env.NODE_ENV === 'development') {
  // eslint-disable-next-line global-require
  const mock = require('./mock').default;
  mock();
}

interface HomeResponse {
  entryVidId: string;
  videoPauseImg: string;
  videoEndImg: string;
  topCarouselInfo: {
    img: string;
  }[];
  bottomCarouselInfo: {
    img: string;
    url: string;
  }[];
  tabs: {
    id: number;
    topic: string;
    subtopic: string;
    img: string;
  }[];
  error?: string;
}
interface TabResponse {
  [id: number]: {
    media: {
      title: string;
      author: string;
      date: string;
      thumbnail: string | null;
      info: string;
      isPinned: boolean;
      type: MediaType;
    }[];
  };
  error?: string;
}
export default {
  getHome: () => api<HomeResponse>('get', '/home'),
  getTab: (params: { id: number }) => api<TabResponse>('get', '/tab', params),
};
