import { defineStore } from 'pinia';

interface State {
  isEnlarged: boolean;
  thumbnail: string;
  youtube: string;
  youtubeCover: string;
  player: null | any;
  playerState: string;
}

const state = (): State => ({
  isEnlarged: false,
  thumbnail: '',
  youtube: '',
  youtubeCover: '',
  player: null,
  playerState: '',
});

const useMedia = defineStore('media', {
  state,
  actions: {
    updateIsEnlarged(state: boolean) {
      this.isEnlarged = state;
    },
    updateThumbnail(img: string) {
      this.thumbnail = img;
    },
    updateYoutube(id: string) {
      this.youtube = id;
    },
    updateYoutubeCover(img: string) {
      this.youtubeCover = img;
    },
    updatePlayer(player: any) {
      this.player = player;
    },
    updatePlayerState(state: string) {
      this.playerState = state;
    },
    closeMedia() {
      this.updateIsEnlarged(false);
      if (this.thumbnail) {
        this.updateThumbnail('');
      }
      if (this.youtube) {
        this.updateYoutube('');
        this.updateYoutubeCover('');
        this.player.destroy();
        this.updatePlayer(null);
      }
    },
  },
});

export default useMedia;
