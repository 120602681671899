import { defineStore } from 'pinia';
interface State {
  error: string;
}

const state = (): State => ({
  error: '',
});

const useError = defineStore('error', {
  state,
  actions: {
    updateError(error: string) {
      this.error = error;
    },
  },
});

export default useError;
