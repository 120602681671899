<template>
  <button class="close"></button>
</template>
<style lang="scss" scoped>
.close {
  width: 0.44rem;
  height: 0.44rem;
  position: absolute;
  right: 0.87%;
  top: 1.8%;
  z-index: 999999999;
  background: assetsUrl('video-close.png') no-repeat center/ 100%;
}
</style>
