import { defineStore } from 'pinia';
interface State {
  isShareVisible: boolean;
  isCopied: boolean;
}

const state = (): State => ({
  isShareVisible: false,
  isCopied: false,
});

const useShare = defineStore('share', {
  state,
  actions: {
    updateIsShareVisible(open: boolean): void {
      this.isShareVisible = open;
    },
    updateIsCopied(open: boolean): void {
      this.isCopied = open;
    },
  },
});

export default useShare;
