import { defineStore } from 'pinia';
import services from '@/services';
import { MediaType } from '@/types/enums';
import useError from './error';

interface State {
  media: {
    title: string;
    author: string;
    month: string;
    day: string;
    thumbnail: string | null;
    info: string;
    isPinned: boolean;
    type: MediaType;
  }[];
  currentTab: number;
  error: string;
}

const state = (): State => ({
  media: [],
  currentTab: 0,
  error: '',
});

const useTab = defineStore('tab', {
  state,
  actions: {
    async getTab(id: number) {
      try {
        this.updateMedia([]);
        const res = await services.getTab({ id });
        if (res.error) {
          const { updateError } = useError();
          updateError(res.error);
          setTimeout(() => {
            location.reload();
          }, 1000);
          return;
        }
        if (res) {
          this.$patch({ ...res });
        }
      } catch (err) {
        const { updateError } = useError();
        updateError('ERROR__SERVER_ERROR');
      }
    },
    updateMedia(list: State['media']) {
      this.media = list;
    },
    updateCurrentTab(id: number) {
      this.currentTab = id;
    },
  },
});

export default useTab;
