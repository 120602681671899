import { createApp } from 'vue';
import * as Sentry from '@sentry/browser';
import * as Integrations from '@sentry/integrations';
import App from '@/App.vue';
import i18n from '@/i18n';
import '@/preloader';
import '@/styles/global.scss';
import setupLayout from '@/layout';
import { createPinia } from 'pinia';

setupLayout();
const pinia = createPinia();
const app = createApp(App).use(i18n).use(pinia).mount('#app');

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://7d5d8b87aa65457597e1013866821bee@sentry.twrevenue.garenanow.com/207',
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    integrations: [new Integrations.Vue({ app, attachProps: true })],
  });
}
