<template>
  <button type="button"></button>
</template>
<style lang="scss" scoped>
button {
  line-height: 1.2;
  font-size: 0.16rem;
  &.play,
  &.share {
    position: absolute;
  }
  &.play {
    left: 0.56rem;
    top: 2.2rem;
    width: 1.66rem;
    height: 0.65rem;
    background: assetsUrl('btn-banner-top-1-play.png') no-repeat center/ 100%;
  }
  &.share {
    right: 4%;
    top: 11%;
    width: 1.06rem;
    height: 0.5rem;
    background: assetsUrl('btn-share.png') no-repeat center/ 100%;
  }
}
</style>
