import { defineStore } from 'pinia';
import services from '@/services';
import useError from './error';

interface State {
  entryVidId: string;
  videoPauseImg: string;
  videoEndImg: string;
  topCarouselInfo: {
    img: string;
  }[];
  bottomCarouselInfo: {
    img: string;
    url: string;
  }[];
  tabs: {
    id: number;
    topic: string;
    subtopic: string;
    img: string;
  }[];
  isInit: boolean;
}

const state = (): State => ({
  entryVidId: '',
  videoPauseImg: '',
  videoEndImg: '',
  topCarouselInfo: [],
  bottomCarouselInfo: [],
  tabs: [],
  isInit: false,
});

const useHome = defineStore('home', {
  state,
  actions: {
    async getHome() {
      try {
        const res = await services.getHome();
        if (res.error) {
          const { updateError } = useError();
          updateError(res.error);
          return;
        }
        if (res) {
          this.$patch({ ...res });
          this.updateIsInit(true);
        }
      } catch (err) {
        const { updateError } = useError();
        updateError('ERROR__SERVER_ERROR');
      }
    },
    updateIsInit(state: boolean) {
      this.isInit = state;
    },
  },
});

export default useHome;
